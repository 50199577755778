var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"p-2 p-lg-3 my-1 my-lg-3 text-hover-info",staticStyle:{"cursor":"pointer"}},[_vm._v(" Laboratorium ")]),(_vm.research_permit_letter_access)?_c('router-link',{attrs:{"to":"#"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-envelope-open-text text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Surat Ijin Penelitian ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,2463105937)}):_vm._e(),(_vm.tool_access)?_c('router-link',{attrs:{"to":"/tools"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-flask-empty-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Alat ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,2876829719)}):_vm._e(),(_vm.tool_categories_access)?_c('router-link',{attrs:{"to":"/tool-categories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-table text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Kategori Alat ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,2081030324)}):_vm._e(),(_vm.lab_material_access)?_c('router-link',{attrs:{"to":"/lab-materials"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-vial text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bahan ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,3395782764)}):_vm._e(),(_vm.lab_material_categories_access)?_c('router-link',{attrs:{"to":"/lab-material-categories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-table text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Kategori Bahan ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,3562996552)}):_vm._e(),(_vm.lab_material_stock_access)?_c('router-link',{attrs:{"to":"/lab-material-stocks"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-boxes text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Stok Bahan ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,1679899422)}):_vm._e(),(_vm.log_book_access)?_c('router-link',{attrs:{"to":"/log-books"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-book-open-page-variant text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Catatan Praktikum & Penelitian ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,1670709464)}):_vm._e(),(_vm.tool_borrower_access)?_c('router-link',{attrs:{"to":"/tool-borrowers"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-flask-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Peminjaman Alat ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,2733924665)}):_vm._e(),(_vm.uom)?_c('router-link',{attrs:{"to":"/masters/uom/list"}},[_c('div',{staticClass:"list list-hover text-classroom-hover"},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-microscope text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Satuan ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":"#"}},[_vm._v("Laboratorium")])])])])])]):_vm._e(),(_vm.practicum_permit)?_c('router-link',{attrs:{"to":"/practicum-permit"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-file text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Surat Izin Praktikum ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,3367371789)}):_vm._e(),(_vm.thesis_research_permit)?_c('router-link',{attrs:{"to":"/thesis-research-permit"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-file text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Surat Izin Penelitian Skripsi/Tesis ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,610270204)}):_vm._e(),(_vm.sem_registration)?_c('router-link',{attrs:{"to":"/sem-registration"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-file text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Pendaftaran Karakterisasi SEM ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,4036371205)}):_vm._e(),(_vm.letter_access)?_c('router-link',{attrs:{"to":"/letters/lab-loan-application/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-file text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Surat Izin Pemakaian Lab ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,3716279921)}):_vm._e(),(_vm.letter_access)?_c('router-link',{attrs:{"to":"/letters/lab-tool-loan-application/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-classroom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"fas fa-file text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Surat Izin Peminjaman Alat Lab ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Laboratorium")])])])])])]}}],null,false,385682570)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }