<template>
  <ul class="menu-nav">

    <li class="menu-section">
      <h4 class="menu-text">LMS</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/classrooms') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-chalkboard-teacher"></i>
        <span class="menu-text">Kelas Saya</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <li aria-haspopup="true" class="menu-item menu-item-parent">
            <span class="menu-link">
              <span class="menu-text">Kelas Saya</span>
            </span>
          </li>

          <router-link
            v-for="item in myClassroom"
            :key="item.id"
            :to="`/classrooms/detail/${item.id}`"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{item.name}}</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

    <router-link
      to="/agenda"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <i class="menu-icon h1"><b-icon-calendar2-event></b-icon-calendar2-event></i>
          <span class="menu-text">Agenda</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/bank"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <i class="menu-icon h1"><b-icon-book-half></b-icon-book-half></i>
          <span class="menu-text">Bank Materi & Soal</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/personal-storage"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <i class="menu-icon h1"><b-icon-file></b-icon-file></i>
          <span class="menu-text">File Personal</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/presence/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <i class="menu-icon mdi mdi-timetable" style="font-size: 25px"></i>
          <span class="menu-text">Kehadiran</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/school-report-card/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <i class="menu-icon mdi mdi-book-open" style="font-size: 30px"></i>
          <span class="menu-text">Kehadiran</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/courses"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <!-- <i class="menu-icon flaticon2-architecture-and-city"></i> -->
          <i class="menu-icon mdi mdi-library" style="font-size: 30px"></i>
          <span class="menu-text">Pustaka</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/collections"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon"
            ><b-icon-collection-fill></b-icon-collection-fill
          ></i>
          <span class="menu-text">Koleksi</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/formats"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-file"></i>
          <span class="menu-text">Format</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon"
            ><b-icon-collection-fill></b-icon-collection-fill
          ></i>
          <span class="menu-text">Kategori</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/masters/presence-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="presenceType"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-clipboard-list"></i>
          <span class="menu-text">Jenis Absen</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/masters/meeting-platforms"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-video"></i>
          <span class="menu-text">Platform Pertemuan</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/minimum-completeness-criterias"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon mdi mdi-tune-vertical fa-2x"></i>
          <span class="menu-text">Kriteria Ketuntasan Minimal(KKM)</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/competence-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">Jenis Kompetensi</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/masters/sections"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-clipboard-list"></i>
          <span class="menu-text">Seksi</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/masters/classwork-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-check-square"></i>
          <span class="menu-text">Jenis Tugas</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/content-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-folder"></i>
          <span class="menu-text">Jenis Konten</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/duration-units"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-clock"></i>
          <span class="menu-text">Satuan Durasi</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/question-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-question-circle"></i>
          <span class="menu-text">Jenis Pertanyaan</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>

<script>
import api from '@/core/services/api.service.js'
import module from "@/core/modules/CrudModule.js";

export default {
  name: "LMSAside",
  data(){
      return{
          myClassroom: []
      }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    async getMyClassroom() {
      api.get("api/users/me").then((response) => {
        api
          .get(`api/classrooms/my-classrooms/${response.data.data.id}`)
          .then((result) => {
            this.myClassroom = result.data.data;
          });
      });
    },
  },
  mounted(){
      this.getMyClassroom()
  }
};
</script>
