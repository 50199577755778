var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"p-2 p-lg-3 my-1 my-lg-3 text-primary text-hover-primary",staticStyle:{"cursor":"pointer"}},[_vm._v(" Kartu Bimbingan ")]),(_vm.academic_guidance)?_c('router-link',{attrs:{"to":"/academic-guidance"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bimbingan Akademik ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,4271326029)}):_vm._e(),(_vm.field_practice_guidance)?_c('router-link',{attrs:{"to":"/field-practice-guidance"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bimbingan PKL ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,1144387039)}):_vm._e(),(_vm.creativity_program_guidance)?_c('router-link',{attrs:{"to":"/creativity-program-guidance"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bimbingan PKM ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,3675892062)}):_vm._e(),(_vm.essay_guidance)?_c('router-link',{attrs:{"to":"/essay-guidance"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bimbingan Skripsi ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,3236808321)}):_vm._e(),(_vm.thesis_guidance)?_c('router-link',{attrs:{"to":"/thesis-guidance"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bimbingan Tesis ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,2609847856)}):_vm._e(),(_vm.activity_guidance)?_c('router-link',{attrs:{"to":"/activity-guidance"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bimbingan Kegiatan MBKM ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,2353766971)}):_vm._e(),(_vm.student_activity_guidance)?_c('router-link',{attrs:{"to":"/student-activity-guidance"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Bimbingan Kegiatan Kemahasiswaan ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,1800512105)}):_vm._e(),(_vm.print_request)?_c('router-link',{attrs:{"to":"/print-request"},scopedSlots:_vm._u([{key:"default",fn:function({href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"mdi mdi-card-bulleted-outline text-primary icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Pengajuan Cetak ")]),_c('a',{staticClass:"text-dark-75 text-hover-primary",attrs:{"href":href}},[_vm._v("Kartu Bimbingan")])])])])])]}}],null,false,362221398)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }