import { render, staticRenderFns } from "./Aside.vue?vue&type=template&id=b6c352aa&scoped=true&"
import script from "./Aside.vue?vue&type=script&lang=js&"
export * from "./Aside.vue?vue&type=script&lang=js&"
import style0 from "./Aside.vue?vue&type=style&index=0&id=b6c352aa&prod&lang=scss&"
import style1 from "./Aside.vue?vue&type=style&index=1&id=b6c352aa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6c352aa",
  null
  
)

export default component.exports