<template>
  <div>
    <!-- <router-link to="/classrooms"> -->
    <h3 class="p-2 p-lg-3 my-1 my-lg-3 text-primary" style="cursor: pointer">
      Pendaftaran Ujian
    </h3>
    <!-- </router-link> -->
    <!--begin::List-->
    <router-link v-if="work_practice_exam_registrations"
      to="/work-practice-exam-registrations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Pendaftaran Ujian PKL </span>
              <a :href="href" class="text-dark-75">Pendaftaran Ujian</a>
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link v-if="pra_essay_seminar_registrations"
      to="/pra-essay-seminar-registrations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0">
                Pendaftaran Seminar Pra Skripsi
              </span>
              <a :href="href" class="text-dark-75">Pendaftaran Ujian</a>
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link v-if="essay_exam_registrations"
      to="/essay-exam-registrations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0">
                Pendaftaran Sidang Skripsi
              </span>
              <a :href="href" class="text-dark-75">Pendaftaran Ujian</a>
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link v-if="pra_essay_seminar_registrations"
      to="/pra-thesis-seminar-registrations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0">
                Pendaftaran Seminar Pra Tesis
              </span>
              <a :href="href" class="text-dark-75">Pendaftaran Ujian</a>
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link v-if="essay_exam_registrations"
      to="/thesis-exam-registrations"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="flaticon2-sheet text-primary ml-1 icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0">
                Pendaftaran Sidang Tesis
              </span>
              <a :href="href" class="text-dark-75">Pendaftaran Ujian</a>
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  data() {
    return {
      // access
      work_practice_exam_registrations: false,
      pra_essay_seminar_registrations: false,
      essay_exam_registrations: false,
    };
  },
  methods: {
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1201" || access_right[a] == "1202") {
          this.work_practice_exam_registrations = true;
        }
        if (access_right[a] == "1204" || access_right[a] == "1205") {
          this.pra_essay_seminar_registrations = true;
        }
        if (access_right[a] == "1207" || access_right[a] == "1208") {
          this.essay_exam_registrations = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
  },
};
</script>

<style scoped>
.text-custom-hover:hover .text-primary {
  color: #ff6000 !important;
}

.text-custom-hover:hover .text-dark-75 {
  color: #ff6000 !important;
}

.text-custom {
  color: #ff6000;
}

.icon-size {
  font-size: 2.3rem;
}

.menu-list-active {
  background-color: #f3f6f9;
}
.menu-list-active span {
  color: #ff6000 !important;
}
.menu-list-active a {
  color: #ff6000 !important;
}
.menu-list-active i {
  color: #ff6000 !important;
}
</style>