<template>
  <div>
    <!-- <router-link to="/classrooms"> -->
    <h3 class="p-2 p-lg-3 my-1 my-lg-3 text-hover-info" style="cursor: pointer">
      Laboratorium
    </h3>
    <!-- </router-link> -->
    <!--begin::List-->
    <router-link to="#" v-if="research_permit_letter_access" v-slot="{ href, navigate, isActive, isExactActive }">
      <div class="list list-hover text-custom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="fas fa-envelope-open-text text-primary icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Surat Ijin Penelitian </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link
      to="/tools"
      v-if="tool_access"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i
                  class="mdi mdi-flask-empty-outline text-primary icon-size"
                ></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Alat </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link
      to="/tool-categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="tool_categories_access"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="fas fa-table text-primary icon-size"></i>
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Kategori Alat </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link
      to="/lab-materials"
      v-if="lab_material_access"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="fas fa-vial text-primary icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Bahan </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link
      to="/lab-material-categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="lab_material_categories_access"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="fas fa-table text-primary icon-size"></i>
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Kategori Bahan </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link
      to="/lab-material-stocks"
      v-if="lab_material_stock_access"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="fas fa-boxes text-primary icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Stok Bahan </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link
      to="/log-books"
      v-if="log_book_access"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i
                  class="mdi mdi-book-open-page-variant text-primary icon-size"
                ></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0">
                Catatan Praktikum & Penelitian
              </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link
      to="/tool-borrowers"
      v-if="tool_borrower_access"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <div
        class="list list-hover text-custom-hover"
        :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]"
      >
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="mdi mdi-flask-outline text-primary icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Peminjaman Alat </span>
              <a :href="href" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link to="/masters/uom/list" v-if="uom">
      <div class="list list-hover text-classroom-hover">
        <div class="list-item hoverable p-2 p-lg-3 mb-2">
          <div class="d-flex align-items-center">
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light mr-4">
              <span
                class="
                  symbol-label
                  bg-hover-white
                  svg-icon svg-icon-lg svg-icon-primary
                "
              >
                <i class="mdi mdi-microscope text-primary icon-size"></i>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Satuan </span>
              <a href="#" class="text-dark-75 text-hover-primary"
                >Laboratorium</a
              >
            </div>
            <!--End::Text-->
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!-- begin::List-->
    <router-link to="/practicum-permit" v-if="practicum_permit" v-slot="{ href, navigate, isActive, isExactActive }">
      <div class="list list-hover text-classroom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light mr-4">
              <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                <i class="fas fa-file text-primary icon-size"></i>
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Surat Izin Praktikum </span>
              <a :href="href" class="text-dark-75 text-hover-primary">Laboratorium</a>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List -->
    <!-- begin::List-->
    <router-link to="/thesis-research-permit" v-if="thesis_research_permit" v-slot="{ href, navigate, isActive, isExactActive }">
      <div class="list list-hover text-classroom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light mr-4">
              <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                <i class="fas fa-file text-primary icon-size"></i>
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Surat Izin Penelitian Skripsi/Tesis </span>
              <a :href="href" class="text-dark-75 text-hover-primary">Laboratorium</a>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List -->
    <!--begin::List-->
    <router-link to="/sem-registration" v-if="sem_registration" v-slot="{ href, navigate, isActive, isExactActive }">
      <div class="list list-hover text-classroom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light mr-4">
              <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                <i class="fas fa-file text-primary icon-size"></i>
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Pendaftaran Karakterisasi SEM </span>
              <a :href="href" class="text-dark-75 text-hover-primary">Laboratorium</a>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link to="/letters/lab-loan-application/list" v-if="letter_access" v-slot="{ href, navigate, isActive, isExactActive }">
      <div class="list list-hover text-classroom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light mr-4">
              <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                <i class="fas fa-file text-primary icon-size"></i>
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Surat Izin Pemakaian Lab </span>
              <a :href="href" class="text-dark-75 text-hover-primary">Laboratorium</a>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
    <!--begin::List-->
    <router-link to="/letters/lab-tool-loan-application/list" v-if="letter_access" v-slot="{ href, navigate, isActive, isExactActive }">
      <div class="list list-hover text-classroom-hover" :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]">
        <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light mr-4">
              <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                <i class="fas fa-file text-primary icon-size"></i>
              </span>
            </div>
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <span class="text-primary mb-0"> Surat Izin Peminjaman Alat Lab </span>
              <a :href="href" class="text-dark-75 text-hover-primary">Laboratorium</a>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <!--end::List-->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  data() {
    return {
      // access
      research_permit_letter_access: false,
      tool_access: false,
      lab_material_access: false,
      lab_material_stock_access: false,
      log_book_access: false,
      tool_borrower_access: false,
      lab_material_categories_access: false,
      tool_categories_access: false,
      uom: false,
      practicum_permit: false,
      thesis_research_permit: false,
      sem_registration: false,
      letter_access: false,
    };
  },
  methods: {
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1101" || access_right[a] == "1102") {
          this.lab_material_access = true;
        }
        if (access_right[a] == "1103" || access_right[a] == "1104") {
          this.tool_access = true;
        }
        if (access_right[a] == "1106" || access_right[a] == "1107") {
          this.lab_material_stock_access = true;
        }
        if (access_right[a] == "1108" || access_right[a] == "1109") {
          this.log_book_access = true;
        }
        if (access_right[a] == "1110" || access_right[a] == "1111") {
          this.tool_borrower_access = true;
        }
        if (access_right[a] == "1112" || access_right[a] == "1113") {
          this.tool_categories_access = true;
        }
        if (access_right[a] == "1114" || access_right[a] == "1115") {
          this.lab_material_categories_access = true;
        }
        if (access_right[a] == "9015" || access_right[a] == "9016") {
          this.lab_material_categories_access = true;
        }
        if (access_right[a] == "1116" || access_right[a] == "1117") {
          this.practicum_permit = true;
        }
        if (access_right[a] == "1118" || access_right[a] == "1119") {
          this.thesis_research_permit = true;
        }
        if (access_right[a] == "1120" || access_right[a] == "1121") {
          this.sem_registration = true;
        }
        if (access_right[a] == "1501" || access_right[a] == "1502") {
          this.letter_access = true;
        }
        if (access_right[a] == "9001" || access_right[a] == "9002") {
          this.uom = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
  },
};
</script>

<style scoped>
/* .text-custom-hover:hover {
  font-weight: bold;
} */

.text-custom-hover:hover .text-primary {
  color: #ff6000 !important;
}

.text-custom-hover:hover .text-dark-75 {
  color: #ff6000 !important;
}

.text-custom {
  color: #ff6000;
}

.icon-size {
  font-size: 2.3rem;
}

.menu-list-active {
  background-color: #f3f6f9;
}
.menu-list-active span {
  color: #ff6000 !important;
}
.menu-list-active a {
  color: #ff6000 !important;
}
.menu-list-active i {
  color: #ff6000 !important;
}
</style>
