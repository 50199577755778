var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"p-2 p-lg-3 my-1 my-lg-3 text-primary",staticStyle:{"cursor":"pointer"}},[_vm._v(" Pendaftaran Ujian ")]),(_vm.work_practice_exam_registrations)?_c('router-link',{attrs:{"to":"/work-practice-exam-registrations"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Pendaftaran Ujian PKL ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Pendaftaran Ujian")])])])])])]}}],null,false,2455119894)}):_vm._e(),(_vm.pra_essay_seminar_registrations)?_c('router-link',{attrs:{"to":"/pra-essay-seminar-registrations"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Pendaftaran Seminar Pra Skripsi ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Pendaftaran Ujian")])])])])])]}}],null,false,3166673533)}):_vm._e(),(_vm.essay_exam_registrations)?_c('router-link',{attrs:{"to":"/essay-exam-registrations"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Pendaftaran Sidang Skripsi ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Pendaftaran Ujian")])])])])])]}}],null,false,4161887783)}):_vm._e(),(_vm.pra_essay_seminar_registrations)?_c('router-link',{attrs:{"to":"/pra-thesis-seminar-registrations"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Pendaftaran Seminar Pra Tesis ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Pendaftaran Ujian")])])])])])]}}],null,false,266537996)}):_vm._e(),(_vm.essay_exam_registrations)?_c('router-link',{attrs:{"to":"/thesis-exam-registrations"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"flaticon2-sheet text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Pendaftaran Sidang Tesis ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Pendaftaran Ujian")])])])])])]}}],null,false,1720960374)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }