<template>
  <ul class="menu-nav">
    <li class="menu-section" v-if="masterTitle">
      <h4 class="menu-text">Master Penjadwalan</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/masters/buildings/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-school" style="font-size: 1.6rem"></i>
          <span class="menu-text">Gedung</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/rooms/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-chalkboard" style="font-size: 1.6rem"></i>
          <span class="menu-text">Ruang</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/times/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-clock" style="font-size: 1.6rem"></i>
          <span class="menu-text">Jam</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/study-programs/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-book" style="font-size: 1.6rem"></i>
          <span class="menu-text">Program Studi</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/subjects/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-book" style="font-size: 1.6rem"></i>
          <span class="menu-text">Mata Kuliah</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/periode-types"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="periodeType"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon mdi mdi-timer-sand" style="font-size: 1.6rem"></i>
          <span class="menu-text">Jenis Periode</span>
        </a>
      </li>
    </router-link>

    <!-- <li class="menu-section" v-if="masterTitle">
      <h4 class="menu-text">Master Laboratorium</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/tool-categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="announcementCategory"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-table" style="font-size: 1.6rem"></i>
          <span class="menu-text">Kategori Alat</span>
        </a>
      </li>
    </router-link>
    <router-link
      to="/lab-material-categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="announcementCategory"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-table" style="font-size: 1.6rem"></i>
          <span class="menu-text">Kategori Bahan</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/uom/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="periodeType"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i
            class="menu-icon fas fa-balance-scale"
            style="font-size: 1.6rem"
          ></i>
          <span class="menu-text">Satuan</span>
        </a>
      </li>
    </router-link> -->

    <!-- <li class="menu-section">
      <h4 class="menu-text">Master Khusus</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li> -->

    <li class="menu-section">
      <h4 class="menu-text">Master</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/masters/announcement-categories"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="announcementCategory"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon mdi mdi-bullhorn" style="font-size: 1.6rem"></i>
          <span class="menu-text">Kategori Pengumuman</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/roles"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="role"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-circle"></i>
          <span class="menu-text">Peran</span>
        </a>
      </li>
    </router-link>

    <li class="menu-section" v-if="settingTitle">
      <h4 class="menu-text">Setting</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>
    <router-link
      to="/settings/edit/1"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="settingView"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon flaticon2-gear"></i>
          <span class="menu-text">Settings</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/user-managements/role-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="roleAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-user-cog"></i>
          <span class="menu-text">Hak Akses Peran</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/user-managements/user-access"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="userAccess"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users-cog"></i>
          <span class="menu-text">Hak Akses User</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "Master",
  data() {
    return {
      // access
      // master
      masterTitle: false,
      school: false,
      user: false,
      collection: false,
      format: false,
      category: false,
      presenceType: false,
      meetingPlatform: false,
      announcementCategory: false,
      periodeType: false,
      competenceType: false,
      classworkType: false,
      contentType: false,
      durationUnit: false,
      questionType: false,
      role: false,
      // setting
      settingTitle: false,
      settingView: false,
      roleAccess: false,
      userAccess: false,
    };
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9001" || access_right[a] == "9002") {
          this.user = true;
          this.masterTitle = true;
        }
        if (access_right[a] == "9003" || access_right[a] == "9004") {
          this.masterTitle = true;
          this.collection = true;
        }
        if (access_right[a] == "9005" || access_right[a] == "9006") {
          this.masterTitle = true;
          this.format = true;
        }
        if (access_right[a] == "9007" || access_right[a] == "9008") {
          this.masterTitle = true;
          this.category = true;
        }
        if (access_right[a] == "9009" || access_right[a] == "9010") {
          this.masterTitle = true;
          this.presenceType = true;
        }
        if (access_right[a] == "9011" || access_right[a] == "9012") {
          this.masterTitle = true;
          this.meetingPlatform = true;
        }
        if (access_right[a] == "9013" || access_right[a] == "9014") {
          this.masterTitle = true;
          this.announcementCategory = true;
        }
        if (access_right[a] == "9015" || access_right[a] == "9016") {
          this.masterTitle = true;
          this.periodeType = true;
        }
        if (access_right[a] == "9017" || access_right[a] == "9018") {
          this.masterTitle = true;
          this.competenceType = true;
        }
        if (access_right[a] == "9019" || access_right[a] == "9020") {
          this.masterTitle = true;
          this.classworkType = true;
        }
        if (access_right[a] == "9021" || access_right[a] == "9022") {
          this.masterTitle = true;
          this.contentType = true;
        }
        if (access_right[a] == "9023" || access_right[a] == "9024") {
          this.masterTitle = true;
          this.durationUnit = true;
        }
        if (access_right[a] == "9025" || access_right[a] == "9026") {
          this.masterTitle = true;
          this.questionType = true;
        }
        if (access_right[a] == "9027" || access_right[a] == "9028") {
          this.masterTitle = true;
          this.role = true;
        }
        if (access_right[a] == "9029" || access_right[a] == "9030") {
          this.settingTitle = true;
          this.settingView = true;
        }
        if (access_right[a] == "9031" || access_right[a] == "9032") {
          this.settingTitle = true;
          this.roleAccess = true;
        }
        if (access_right[a] == "9033" || access_right[a] == "9034") {
          this.settingTitle = true;
          this.userAccess = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
    this.$root.$on("refreshMasterAside", () => {
      //('refreshMasterAside')
      this.checkAccess();
    });
  },
};
</script>
