<template>
  <div>
    <!-- <router-link to="/#"> -->
    <h3 class="p-2 p-lg-3 my-1 my-lg-3 text-primary text-hover-primary" style="cursor: pointer">
      Kartu Bimbingan
    </h3>
    <!-- </router-link> -->
    <!--begin::List-->
      <router-link to="/academic-guidance" v-if="academic_guidance" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2" @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Bimbingan Akademik
                </span>
                <a :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/field-practice-guidance" v-if="field_practice_guidance" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2"  @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Bimbingan PKL
                </span>
                <a :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/creativity-program-guidance" v-if="creativity_program_guidance" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2"  @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Bimbingan PKM
                </span>
                <a  :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/essay-guidance" v-if="essay_guidance" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2"  @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Bimbingan Skripsi
                </span>
                <a :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/thesis-guidance" v-if="thesis_guidance" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2"  @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Bimbingan Tesis
                </span>
                <a :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/activity-guidance" v-if="activity_guidance" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2"  @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Bimbingan Kegiatan MBKM
                </span>
                <a :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/student-activity-guidance" v-if="student_activity_guidance" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2"  @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Bimbingan Kegiatan Kemahasiswaan
                </span>
                <a :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
    <!--begin::List-->
      <router-link to="/print-request" v-if="print_request" v-slot="{href, navigate, isActive, isExactActive }">
        <div 
          class="list list-hover text-custom-hover" 
          :class="[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
          ]"
        >
          <div class="list-item hoverable p-2 p-lg-3 mb-2"  @click="navigate">
            <div class="d-flex align-items-center">
              <!--begin::Symbol-->
              <div class="symbol symbol-40 symbol-light mr-4">
                <span class="symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary">
                  <i class="mdi mdi-card-bulleted-outline text-primary icon-size"></i>
                </span>
              </div>
              <!--end::Symbol-->
              <!--begin::Text-->
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <span class="text-primary mb-0">
                  Pengajuan Cetak
                </span>
                <a :href="href" class="text-dark-75 text-hover-primary">Kartu Bimbingan</a>
              </div>
              <!--End::Text-->
            </div>
          </div>
        </div>
      </router-link>
    <!--end::List-->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  data() {
    return {
      // access
      academic_guidance: false,
      field_practice_guidance: false,
      creativity_program_guidance: false,
      essay_guidance: false,
      thesis_guidance: false,
      activity_guidance: false,
      student_activity_guidance: false,
      print_request: false,
    };
  },
  methods: {
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      console.log('run')
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1301" || access_right[a] == "1302") {
          this.academic_guidance = true;
        }
        if (access_right[a] == "1303" || access_right[a] == "1304") {
          this.field_practice_guidance = true;
        }
        if (access_right[a] == "1305" || access_right[a] == "1306") {
          this.creativity_program_guidance = true;
        }
        if (access_right[a] == "1307" || access_right[a] == "1308") {
          this.essay_guidance = true;
        }
        if (access_right[a] == "1309" || access_right[a] == "1310") {
          this.thesis_guidance = true;
        }
        if (access_right[a] == "1311" || access_right[a] == "1312") {
          this.student_activity_guidance = true;
        }
        if (access_right[a] == "1313" || access_right[a] == "1314") {
          this.print_request = true;
        }
        if (access_right[a] == "1315" || access_right[a] == "1316") {
          this.activity_guidance = true;
        }
      }
    },
  },
  mounted() {
    this.checkAccess();
  },
};
</script>

<style scoped>
/* .text-custom-hover:hover {
  font-weight: bold;
} */

.text-custom-hover:hover .text-primary {
  color: #ff6000 !important;
}

.text-custom-hover:hover .text-dark-75 {
  color: #ff6000 !important;
}

.text-custom{
  color: #ff6000;
}

.icon-size{
  font-size: 2.3rem;
}

.menu-list-active {
    background-color: #F3F6F9;
}
.menu-list-active span {
    color: #ff6000 !important;
}
.menu-list-active a {
    color: #ff6000 !important;
}
.menu-list-active i {
    color: #ff6000 !important;
}
</style>