<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="aside-primary d-flex flex-column align-items-center flex-row-auto"
      style="background-color: #253b80"
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="
          aside-nav
          d-flex
          flex-column
          align-items-center
          flex-column-fluid
          pt-0
          scroll scroll-pull
          ps
        "
        style="height: 528px"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist">
          <!--begin::Item-->
          <router-link to="/dashboard" v-if="dashboard">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Dashboard'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg active"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="5"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg src="/media/svg/icons/Home/Home.svg" /> -->
                  <i class="fas fa-home"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="#" v-if="academic">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Akademik'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                data-toggle="tab"
                v-on:click="setActiveTab"
                data-tab="4"
              >
                <span class="svg-icon svg-icon-xl">
                  <i class="fas fa-graduation-cap"></i>
                  <!-- <inline-svg src="/media/svg/icons/Communication/Group.svg" /> -->
                </span>
              </a>
            </li>
          </router-link>
          <!--end::Item-->
          <!--begin::Item-->
          <router-link to="#" v-if="guidanceCard">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Kartu Bimbingan'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="8"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="mdi mdi-card-bulleted" style="font-size: 2rem"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="#" v-if="field_practice">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Administrasi Umum'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="11"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-file-alt"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="#" v-if="curriculum_schedule">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Jadwal & Kurikulum'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="12"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="flaticon2-calendar-1"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="#" v-if="Laboratory">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Laboratorium'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="9"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="fas fa-flask"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <router-link
            to="#"
            v-if="exam_registrations"
          >
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Pendaftaran Ujian'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="7"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <i class="flaticon2-sheet"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!-- end::item -->
          <!--begin::Item-->
          <!-- <router-link to="/schedules/table">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Laporan'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="13"
              >
                <span class="svg-icon svg-icon-xl">
                  <i class="fas fa-file"></i>
                </span>
              </a>
            </li>
          </router-link> -->
          <!-- end::item -->
          <!--begin::Item-->
          <!-- <router-link to="/classrooms">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'LMS'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="10"
              >
                <span class="svg-icon svg-icon-xl">
                  <i class="fab fa-leanpub"></i>
                </span>
              </a>
            </li>
          </router-link> -->
          <!-- end::item -->
          <!--begin::Item-->
          <!-- `/message-box/messages/${getUser().id}/${friend.id}` -->
          <!-- <router-link 
            :to="'/message-box/messages/' + currentUser.id + '/' + friends[0].id"
            v-if="message_box && friends.length > 0"
          > -->
          <li
            class="nav-item mb-1"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Chat'"
            @click="handleChatAside"
            v-if="chatAccess"
          >
            <!-- v-if="message_box && friends.length > 0" -->
            <a
              href="javascript:void(0)"
              class="nav-link btn btn-icon btn-clean btn-lg"
              v-on:click="setActiveTab"
              data-toggle="tab"
              data-tab="6"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <!-- <b-icon-mailbox></b-icon-mailbox> -->
                <i class="fas fa-comments"></i>
                <!--end::Svg Icon-->
              </span>
            </a>
          </li>
          <!-- </router-link> -->
          <!-- end::item -->
          <!--begin::Item-->
          <router-link to="#" v-if="setting_master">
            <li
              class="nav-item mb-1"
              data-placement="right"
              data-container="body"
              data-boundary="window"
              v-b-tooltip.hover.right="'Setting dan Master Data'"
            >
              <a
                href="#"
                class="nav-link btn btn-icon btn-clean btn-lg"
                v-on:click="setActiveTab"
                data-toggle="tab"
                data-tab="3"
              >
                <span class="svg-icon svg-icon-xl">
                  <!--begin::Svg Icon-->
                  <!-- <inline-svg src="/media/svg/icons/Code/Settings4.svg" /> -->
                  <i class="flaticon2-settings"></i>
                  <!--end::Svg Icon-->
                </span>
              </a>
            </li>
          </router-link>
          <!--begin::Item-->

          <!--end::Item-->
          <!--begin::Item-->
          <!-- <li
            class="nav-item mb-1"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            v-b-tooltip.hover.right="'Metronic Features'"
          >
            <a
              href="#"
              class="nav-link btn btn-icon btn-clean btn-lg"
              data-toggle="tab"
              v-on:click="setActiveTab"
              data-tab="1"
            >
              <span class="svg-icon svg-icon-xl">
                <inline-svg src="media/svg/icons/Communication/Group.svg" />
              </span>
            </a>
          </li> -->
          <!--end::Item-->
        </ul>
        <!--end::Nav-->
      </div>
      <!--end::Nav Wrapper-->
      <!--begin::Footer-->
      <div
        class="
          aside-footer
          d-flex
          flex-column
          align-items-center
          flex-column-auto
          pb-6 pb-lg-6
          mb-3
        "
      >
        <!--begin::Aside Toggle-->
        <span
          class="aside-toggle btn btn-icon btn-toggle-aside shadow-sm"
          id="kt_aside_toggle"
          ref="kt_aside_toggle"
          data-placement="right"
          data-container="body"
          data-boundary="window"
          v-b-tooltip.hover.right="'Toggle Aside'"
          @click="handleAsideToggle"
          v-if="
            $route.fullPath !=
            `/message-box/messages/${$route.params.user_id}/${$route.params.message_to}`
          "
        >
          <i class="ki ki-bold-arrow-back icon-sm text-white"></i>
        </span>
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <!-- <KTQuickActions></KTQuickActions> -->
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <!-- <KTQuickPanel></KTQuickPanel> -->
        <!--end::Quick Panel-->
        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <div
      class="aside-secondary d-flex flex-row-fluid"
      style="background-position: right 45px bottom"
    >
      <!--begin::Workspace-->
      <div
        class="aside-workspace"
        style="
          height: 824px;
          overflow: hidden;
          background-image: url('/img/aside/bg-aside-1-2.png');
          background-repeat: no-repeat;
          background-position: bottom;
        "
      >
        <!--begin::Tab Content-->
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <!--begin::Tab Pane 0-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-10 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <KTMenu></KTMenu>
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->
          <!--begin::Tab Pane 1-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <!--begin::Form-->
            <form class="p-2 p-lg-3">
              <div class="d-flex">
                <div class="input-icon h-40px">
                  <input
                    type="text"
                    class="
                      form-control form-control-lg form-control-solid
                      h-40px
                    "
                    placeholder="Search..."
                  />
                  <span>
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/General/Search.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
                <div class="dropdown" v-b-tooltip.hover.right="'Quick actions'">
                  <a
                    href="#"
                    class="
                      btn btn-icon btn-default btn-hover-primary
                      ml-2
                      h-40px
                      w-40px
                      flex-shrink-0
                    "
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span class="svg-icon svg-icon-lg">
                      <!--begin::Svg Icon-->
                      <inline-svg src="/media/svg/icons/Code/Compiling.svg" />
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <div
                    class="dropdown-menu dropdown-menu-md dropdown-menu-right"
                    style=""
                  >
                    <!--begin::Navigation-->
                    <ul class="navi navi-hover py-5">
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-drop"></i>
                          </span>
                          <span class="navi-text">New Group</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-list-3"></i>
                          </span>
                          <span class="navi-text">Contacts</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-rocket-1"></i>
                          </span>
                          <span class="navi-text">Groups</span>
                          <span class="navi-link-badge">
                            <span
                              class="
                                label label-light-primary label-inline
                                font-weight-bold
                              "
                            >
                              new
                            </span>
                          </span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-bell-2"></i>
                          </span>
                          <span class="navi-text">Calls</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-gear"></i>
                          </span>
                          <span class="navi-text">Settings</span>
                        </a>
                      </li>
                      <li class="navi-separator my-3"></li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-magnifier-tool"></i>
                          </span>
                          <span class="navi-text">Help</span>
                        </a>
                      </li>
                      <li class="navi-item">
                        <a href="#" class="navi-link">
                          <span class="navi-icon">
                            <i class="flaticon2-bell-2"></i>
                          </span>
                          <span class="navi-text">Privacy</span>
                          <span class="navi-link-badge">
                            <span
                              class="
                                label label-light-danger label-rounded
                                font-weight-bold
                              "
                              >5</span
                            >
                          </span>
                        </a>
                      </li>
                    </ul>
                    <!--end::Navigation-->
                  </div>
                </div>
              </div>
            </form>
            <!--end::Form-->
            <h3 class="p-2 p-lg-3 my-1 my-lg-3">Projects</h3>
            <!--begin::List-->
            <div class="list list-hover">
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Bravio Application
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By James
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/010-vimeo.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Quick Reports
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By Ana
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/014-kickstarter.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      CRM Reporting Tool
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By Adam
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/009-hot-air-balloon.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      DB Management
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By CRA Team
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/008-infography.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Cloud Service
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By iC Team
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/007-disqus.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0">
                      Disqus Project
                    </span>
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                    >
                      By PV Inc.
                    </a>
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
              <!--begin::Item-->
              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                <div class="d-flex align-items-center">
                  <!--begin::Symbol-->
                  <div class="symbol symbol-40 symbol-light mr-4">
                    <span class="symbol-label bg-hover-white">
                      <inline-svg
                        src="media/svg/misc/006-plurk.svg"
                        class="h-50 align-self-center"
                      />
                    </span>
                  </div>
                  <!--end::Symbol-->
                  <!--begin::Text-->
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <span class="text-dark-75 font-size-h6 mb-0"
                      >Plurk Meeting</span
                    >
                    <a
                      href="#"
                      class="text-muted text-hover-primary font-weight-bold"
                      >By Plurk Team.</a
                    >
                  </div>
                  <!--begin::End-->
                </div>
              </div>
              <!--end::Item-->
            </div>
            <!--end::List-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 2-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <!--end::Tab Pane-->
            <ClassMenu />
            <!--begin::Tab Pane-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 3-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-10 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px bg-transparent"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll bg-transparent"
                  style="max-height: 90vh; position: relative"
                >
                  <Master />
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 4-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <div
              class="aside-menu-wrapper flex-column-fluid px-10 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px bg-transparent"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll bg-transparent"
                  style="max-height: 90vh; position: relative"
                >
                  <DatabaseTeacherStudent />
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 5-->
          <b-tab active class="p-3 px-lg-7 py-lg-5">
            <UserActivity />
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 6-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <ChatAside />
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 7-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <ExamAside />
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 8-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <GuidanceCard />
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 9-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <LaboratoryAside />
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 10-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-10 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <LMSAside />
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 11-->
          <b-tab>
            <!--begin::Aside Menu-->
            <div
              class="aside-menu-wrapper flex-column-fluid px-10 py-5"
              id="kt_aside_menu_wrapper"
            >
              <!--begin::Menu Container-->
              <div
                ref="kt_aside_menu"
                id="kt_aside_menu"
                class="aside-menu min-h-lg-800px bg-transparent"
                data-menu-vertical="1"
                data-menu-scroll="1"
                v-bind:class="asideMenuClass"
              >
                <!-- example static menu here -->
                <perfect-scrollbar
                  class="aside-menu scroll bg-transparent"
                  style="max-height: 90vh; position: relative"
                >
                  <AdministrationAside />
                </perfect-scrollbar>
              </div>
              <!--end::Menu Container-->
            </div>
            <!--end::Aside Menu-->
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 12-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <ScheduleAside />
          </b-tab>
          <!--end::Tab Pane-->

          <!--begin::Tab Pane 13-->
          <b-tab class="p-3 px-lg-7 py-lg-5">
            <ReportAside />
          </b-tab>
          <!--end::Tab Pane-->
        </b-tabs>
        <!--end::Tab Content-->
        <!--end::Workspace-->
      </div>
      <!--end::Secondary-->
    </div>
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<style scoped>
/* toggle aside */
.btn-toggle-aside {
  background-color: #ff6000;
}
.btn-toggle-aside:hover {
  background-color: #253b80;
}

/* default color */
.btn.btn-clean span i {
  color: white !important;
  background-color: transparent;
  border-color: transparent;
}

/* btn hover */
.btn.btn-clean:hover {
  color: yellow !important;
  background-color: #ff6000 !important;
  border-color: transparent;
}

.btn.btn-clean:hover span i {
  color: white !important;
}

.btn.btn-clean:hover span inline-svg {
  color: #00b0ff !important;
}
/*  */

/* btn active */
.btn.btn-clean:not(:disabled):not(.disabled).active {
  background-color: #ff6000 !important;
  border-color: transparent;
}
.btn.btn-clean:not(:disabled):not(.disabled).active span i {
  color: white !important;
}
/*  */
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";

// LMS
import ClassMenu from "@/view/layout/aside/ClassroomAside";
import UserActivity from "@/view/layout/aside/UserActivity";
import Master from "@/view/layout/aside/Master";
import DatabaseTeacherStudent from "@/view/layout/aside/DatabaseTeacherStudent";
import ChatAside from "@/view/layout/aside/ChatAside";

// fisikaUNJ
import ExamAside from "@/view/layout/aside/ExamRegistrationAside.vue";
import GuidanceCard from "@/view/layout/aside/GuidanceCard.vue";
import LaboratoryAside from "@/view/layout/aside/LaboratoryAside.vue";
import AdministrationAside from "@/view/layout/aside/AdministrationAside.vue";
import LMSAside from "@/view/layout/aside/LMSAside.vue";
import ScheduleAside from "@/view/layout/aside/ScheduleAside.vue";
import ReportAside from "@/view/layout/aside/ReportAside.vue";

import ApiService from "@/core/services/api.service.js";
import TreeselectVue from "../../pages/plugins/Treeselect.vue";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
      base_url: process.env.BASE_URL,
      currentUser: getUser(),
      friends: [],
      // access
      dashboard: false,
      classroom: false,
      agenda: false,
      bank: false,
      file_personal: false,
      presence: true,
      message_box: false,
      rapor_online: false,
      course: false,
      academic: false,
      setting_master: false,
      Laboratory: false,
      exam_registrations: false,
      guidanceCard: false,
      curriculum_schedule: false,
      field_practice: false,
      chatAccess: false,
    };
  },
  components: {
    KTBrand,
    KTMenu,
    KTQuickActions,
    KTQuickUser,
    KTQuickPanel,
    ClassMenu,
    UserActivity,
    Master,
    DatabaseTeacherStudent,
    ChatAside,
    // fisikaUNJ
    ExamAside,
    GuidanceCard,
    LaboratoryAside,
    AdministrationAside,
    LMSAside,
    ScheduleAside,
    ReportAside,
  },
  methods: {
    handleAsideToggle() {
      this.$nextTick(() => {
        // Init Aside Toggle
        KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
      });
    },
    handleChatAside() {
      this.$root.$emit("refreshListChatUser");
      this.$root.$emit("openChat", this.friends[0]);
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    async getMyFriends() {
      let today = new Date().toISOString().substr(0, 10);
      let filterParams = `now=${today}`;
      let response = "";
      if (getUser().role_id == 1) {
        response = await module.paginate(
          `api/users/my-friend-chat-for-teachers/${getUser().id}`,
          `?${filterParams}`
        );
      } else {
        response = await module.paginate(
          `api/users/my-friend-chat-for-students/${getUser().id}`,
          `?${filterParams}`
        );
      }
      this.friends = response.data;
    },
    getAccessRight() {
      ApiService.get("api/users/me").then((data) => {
        // get access user
        ApiService.get("api/users/" + data.data.data.id).then((response) => {
          // this.setActiveMenu(response.data.meta.access_right_display);
          // window.localStorage.setItem(
          //   "access_right_display",
          //   JSON.stringify(response.data.meta.access_right_display)
          // );

          // get access role
          ApiService.get("api/roles/" + data.data.data.role_id)
            .then((result) => {
              let access_right = response.data.meta.access_right_display;
              let position_right = result.data.meta.access_right_display;

              let merge = [...access_right, ...position_right];

              window.localStorage.setItem(
                "access_right_display",
                JSON.stringify(merge)
              );

              this.setActiveMenu(result.data.meta.access_right_display);
              this.$root.$emit("refreshClassroomAside");
              this.$root.$emit("refreshMasterAside");
            })
            .catch((error) => {
              this.setActiveMenu(response.data.meta.access_right_display);
              window.localStorage.setItem(
                "access_right_display",
                JSON.stringify(response.data.meta.access_right_display)
              );
              this.$root.$emit("refreshClassroomAside");
              this.$root.$emit("refreshMasterAside");
            });
        });
      });
    },
    async setActiveMenu(accessParams = "") {
      let access_right;
      if (accessParams == "") {
        let access_right_user = window.localStorage.getItem(
          "access_right_display"
        );
        access_right = JSON.parse(access_right_user);
      } else if (accessParams != "") {
        access_right = accessParams;
      }

      for (let a = 0; a < access_right.length; a++) {
        // general
        if (access_right[a] == 1) {
          this.dashboard = true;
          this.agenda = true;
        }
        if (access_right[a] == 2) {
          this.agenda = true;
        }
        // classroom
        if (access_right[a] == 1001) {
          this.classroom = true;
        }
        // bank
        // for(let bank_code = 2000; bank_code <= 2012; bank_code++){
        // if (access_right[a] == 2001 || access_right[a] == 2003 || access_right[a] == 2006 || access_right[a] == 2008 || access_right[a] == 2010 || access_right[a] == 2012) {
        //   this.bank = true;
        // }
        if (access_right[a] == 2012) {
          this.bank = true;
        }
        // }

        // file-personal
        for (let file_code = 3000; file_code <= 3002; file_code++) {
          // if (access_right[a] == 2001 || access_right[a] == 2003 || access_right[a] == 2006 || access_right[a] == 2008 || access_right[a] == 2010 || access_right[a] == 2012) {
          //   this.bank = true;
          // }
          if (access_right[a] == file_code) {
            this.file_personal = true;
          }
        }

        // presence
        if (access_right[a] == 4001 || access_right[a] == 4002) {
          this.presence = true;
        }

        // message-box
        for (let message_code = 5000; message_code <= 5002; message_code++) {
          if (access_right[a] == message_code) {
            this.message_box = true;
          }
        }

        // rapor-online
        for (let rapor_code = 6000; rapor_code <= 6003; rapor_code++) {
          if (access_right[a] == rapor_code) {
            this.rapor_online = true;
          }
        }

        // course
        for (let course_code = 7000; course_code <= 7011; course_code++) {
          if (access_right[a] == course_code) {
            this.course = true;
          }
        }

        // academic
        for (let academic_code = 8000; academic_code <= 8018; academic_code++) {
          if (access_right[a] == academic_code) {
            this.academic = true;
          }
        }

        // setting
        for (let setting_code = 9000; setting_code <= 9034; setting_code++) {
          if (access_right[a] == setting_code) {
            this.setting_master = true;
          }
        }

        // laboratory
        for (let laboratory = 1100; laboratory <= 1122; laboratory++) {
          if (access_right[a] == laboratory) {
            this.Laboratory = true;
          }
        }

        // exam_registrations
        for (
          let exam_registrations = 1200;
          exam_registrations <= 1210;
          exam_registrations++
        ) {
          if (access_right[a] == exam_registrations) {
            this.exam_registrations = true;
          }
        }

        // guidanceCard
        for (let guidanceCard = 1300; guidanceCard <= 1317; guidanceCard++) {
          if (access_right[a] == guidanceCard) {
            this.guidanceCard = true;
          }
        }
        // curriculum_schedule
        for (let curriculum_schedule = 1400; curriculum_schedule <= 1404; curriculum_schedule++) {
          if (access_right[a] == curriculum_schedule) {
            this.curriculum_schedule = true;
          }
        }
        // field_practice
        for (let field_practice = 1500; field_practice <= 1503; field_practice++) {
          if (access_right[a] == field_practice) {
            this.field_practice = true;
          }
        }
        // chatAccess
        for (let chatAccess = 5000; chatAccess <= 5003; chatAccess++) {
          if (access_right[a] == chatAccess) {
            this.chatAccess = true;
          }
        }
      }
    },
  },
  mounted() {
    this.getMyFriends();

    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });

    this.$root.$on("closeToggleAside", () => {
      this.$nextTick(() => {
        // Init Aside Toggle
        KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
      });
    });

    this.$root.$on("openActivityUser", () => {
      this.tabIndex = 5;
    });

    this.$root.$on("openChatAside", () => {
      this.tabIndex = 6;
    });

    this.setActiveMenu();
    this.$root.$on("setMenuActive", () => {
      this.getAccessRight();
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
  },
};
</script>
