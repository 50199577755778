var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[_vm._m(0),_c('li',{staticClass:"menu-item my-1 menu-item-submenu",class:{ 'menu-item-open': _vm.hasActiveChildren('/letters') },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_vm._m(1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(2),_c('router-link',{attrs:{"to":"/letters/field-practice/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permohonan Pengantar PKL")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/student-information/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Keterangan Mahasiswa")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/scholarship-information/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Keterangan Beasiswa")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/allowance-information/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Keterangan Tunjangan / BPJS")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/activity-guidance-teacher/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Dosen Pembimbing Kegiatan")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/college-leave-application/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Cuti Kuliah")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/college-transfer-application/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Pindah Kuliah")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/space-loan-application/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Peminjaman Ruang")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/sample-research-outside/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Uji Sampel Penelitian di Luar Prodi")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/research-outside/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Penelitian di Luar Prodi")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/lab-loan-application/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permohonan Pemakaian Laboratorium")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/lab-tool-loan-application/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permohonan Peminjaman Alat Laboratorium")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/nonlab-tool-loan/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permohonan Peminjaman Alat Non-Laboratorium")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/chemical-purchase"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permohonan Pembelian Bahan Kimia")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/need-analysis-form/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Analisis Kebutuhan")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/observation-outside/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Observasi di Luar Prodi")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/pkm-application-form/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Praktek Keterampilan Mengajar")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/validation-approval-form/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Persetujuan Validasi")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/validation-request-form/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Permohonan Validasi")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/transcript-request/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan PraTranskrip dan Transkrip")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/transcript-remedial/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Perbaikan PraTranskrip dan Transkrip")])])])]}}])}),_c('router-link',{attrs:{"to":"/letters/approval-request/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item my-1",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active',
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"},on:{"click":_vm.resetWindow}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Permohonan Pengesahan DHS/KRS/KHS")])])])]}}])})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Administrasi Umum")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('i',{staticClass:"menu-icon flaticon2-digital-marketing"}),_c('span',{staticClass:"menu-text"},[_vm._v("Surat Pengantar")]),_c('i',{staticClass:"menu-arrow"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item my-1 menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text"},[_vm._v("Surat Pengantar")])])])
}]

export { render, staticRenderFns }