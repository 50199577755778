var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"p-2 p-lg-3 my-1 my-lg-3 text-primary",staticStyle:{"cursor":"pointer"}},[_vm._v(" Jadwal dan Kurikulum ")]),(_vm.schedule)?_c('router-link',{attrs:{"to":"/schedules/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"far fa-calendar-alt text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Jadwal ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Jadwal dan Kurikulum")])])])])])]}}],null,false,3668054904)}):_vm._e(),(_vm.scheduleAdd)?_c('router-link',{attrs:{"to":"/schedules/add"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"far fa-calendar-alt text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Buat Jadwal ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Jadwal dan Kurikulum")])])])])])]}}],null,false,2085142362)}):_vm._e(),(_vm.schedule)?_c('router-link',{attrs:{"to":"/schedules/table"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"far fa-calendar-alt text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Jadwal Kuliah ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Jadwal dan Kurikulum")])])])])])]}}],null,false,4171488970)}):_vm._e(),(_vm.schedule)?_c('router-link',{attrs:{"to":"/schedules/teacher"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"far fa-calendar-alt text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Jadwal Dosen ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Jadwal dan Kurikulum")])])])])])]}}],null,false,2844395051)}):_vm._e(),(_vm.schedule)?_c('router-link',{attrs:{"to":"/masters/rooms/calendar"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"far fa-calendar-alt text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Ruang ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Jadwal dan Kurikulum")])])])])])]}}],null,false,988800770)}):_vm._e(),(_vm.distribution)?_c('router-link',{attrs:{"to":"/schedules/distribution"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('div',{staticClass:"list list-hover text-custom-hover",class:[
          isActive && 'menu-list-active',
          isExactActive && 'menu-list-active',
        ]},[_c('div',{staticClass:"list-item hoverable p-2 p-lg-3 mb-2",on:{"click":navigate}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light mr-4"},[_c('span',{staticClass:"symbol-label bg-hover-white svg-icon svg-icon-lg svg-icon-primary"},[_c('i',{staticClass:"far fa-calendar-alt text-primary ml-1 icon-size"})])]),_c('div',{staticClass:"d-flex flex-column flex-grow-1 mr-2"},[_c('span',{staticClass:"text-primary mb-0"},[_vm._v(" Sebaran ")]),_c('a',{staticClass:"text-dark-75",attrs:{"href":href}},[_vm._v("Jadwal dan Kurikulum")])])])])])]}}],null,false,4082521093)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }