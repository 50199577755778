<template>
  <div class="activity">
    <!-- <perfect-scrollbar
      class="aside-menu scroll"
      style="max-height: 115vh; position: relative"
    > -->
    <h4 class="p-2 p-lg-3 my-1 my-lg-3 text-primary">User Online</h4>
    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 p-0">
      <template v-for="user in userOn">
        <!--begin::Item-->
        <router-link to="#" href="#" class="navi-item" :key="user.id">
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-warning">
                  <!--begin::Svg Icon-->
                  <img :src="user.photo" class="user-img rounded-circle" />
                  <i class="symbol-badge bg-primary-custom"></i>
                  <!--end::Svg Icon-->
                </span>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">{{ user.name }}</div>
              <!-- <div class="text-muted">11A</div> -->
              <div class="text-muted">{{ convertDate(user.updated_at) }}</div>
            </div>
          </div>
        </router-link>
        <!--end:Item-->
      </template>
      <div class="separator separator-dashed mt-5 mb-5"></div>
      <div class="dropdown-divider mt-3 mb-3"></div>
      <!-- END USER ONLINE / OFFLINE -->
      <!-- PESAN TERBARU -->
      <!-- <h3 class="p-2 p-lg-3 my-1 my-lg-3">{{newMessages.length}} Pesan terbaru</h3> -->
      <h4 class="p-2 p-lg-3 my-1 my-lg-3 text-primary">10 Pesan terbaru</h4>

      <template v-for="(message, i) in newMessages">
        <router-link
          to="#"
          @click.native="closeOffcanvasMessage(i)"
          class="navi-item"
          :key="'message' + i"
        >
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-warning">
                  <!--begin::Svg Icon-->
                  <img
                    :src="message.created_by_photo"
                    class="user-img rounded-circle"
                  />
                  <i
                    class="symbol-badge bg-primary-custom"
                    v-if="message.recipient.is_active == 1"
                  ></i>
                  <!--end::Svg Icon-->
                </span>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">{{ message.created_by_name }}</div>
              <div class="text-muted">{{ message.message }}</div>
              <div class="text-muted">
                {{ convertDate(message.created_at) }}
              </div>
            </div>
          </div>
        </router-link>
      </template>

      <!--end:Item-->
      <div class="separator separator-dashed mt-5 mb-5"></div>
      <div class="dropdown-divider mt-3 mb-3"></div>
      <!-- END PESAN TERBARU -->
      <!-- AKTIVITAS TERBARU -->
      <h4 class="p-2 p-lg-3 my-1 my-lg-3 text-primary">Tugas</h4>

      <div class="separator separator-dashed mt-3 mb-3"></div>

      <b-card no-body class="mb-1" v-if="currentUser.is_complete == 0">
        <b-card-header
          header-tag="header"
          class="p-0 bg-transparent"
          role="tab"
        >
          <b-button
            block
            v-b-toggle.accordion-2
            variant="secondary"
            @click="setAccordion"
            >Melengkapi Profil
            <i class="fas fa-angle-down" v-if="!accordionOpen"></i>
            <i class="fas fa-angle-up" v-if="accordionOpen"></i>
          </b-button>
        </b-card-header>
        <b-collapse
          id="accordion-2"
          visible
          accordion="my-accordion"
          role="tabpanel"
        >
          <b-card-body class="p-2 bg-secondary">
            <ul class="ml-5">
              <template v-if="currentUser.major_id == 1">
                <li v-if="currentUser.pkl_mentor == 0">Dosen Pembimbing PKL</li>
              </template>
              <template v-if="currentUser.major_id == 3">
                <li v-if="currentUser.pkm_mentor == 0">Dosen Pembimbing PKM</li>
              </template>
              <template v-if="currentUser.major_id == 1 || currentUser.major_id == 2 || currentUser.major_id == 3">
                <li v-if="currentUser.academic_mentor == 0">Dosen Pembimbing Akademik</li>
              </template>
              <template v-if="currentUser.major_id == 1 || currentUser.major_id == 3">
                <li v-if="currentUser.academic_mentor == 0">Dosen Pembimbing Akademik</li>
                <li v-if="currentUser.essay_mentor_1 == 0">Dosen Pembimbing 1 Skripsi</li>
                <li v-if="currentUser.essay_mentor_2 == 0">Dosen Pembimbing 2 Skripsi</li>
              </template>
              <template v-if="currentUser.major_id == 2">
                <li v-if="currentUser.thesis_mentor_1 == 0">Dosen Pembimbing 1 Tesis</li>
                <li v-if="currentUser.thesis_mentor_2 == 0">Dosen Pembimbing 2 Tesis</li>
              </template>
              <template v-if="currentUser.role_id == 2">
                <li v-if="currentUser.address == ''">Alamat Rumah</li>
                <li v-if="currentUser.father_name == ''">Nama Ayah</li>
                <li v-if="currentUser.mother_name == ''">Nama Ibu</li>
                <li v-if="currentUser.father_job == ''">Pekerjaan Ayah</li>
                <li v-if="currentUser.mother_job == ''">Pekerjaan Ibu</li>
              </template>
            </ul>
          </b-card-body>
        </b-collapse>
      </b-card>
      <template v-if="classworks.length > 0">
        <template v-for="(classwork, i) in classworks">
          <!-- begin item -->
          <router-link
            to="#"
            @click.native="
              closeOffcanvasClasswork(
                classwork.classroom[classwork.classroom_index].id
              )
            "
            :key="'class' + i"
            class="navi-item"
          >
            <div class="navi-link">
              <!-- <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-warning">
                  <img :src="classwork.classroom[classwork.classroom_index].image_thumbnail" class="user-img rounded-circle" />
                </span>
              </div>
            </div> -->
              <div class="navi-text">
                <template v-if="classwork.classwork_type_id">
                  <div class="font-weight-bolder">
                    {{ classwork.classroom[classwork.classroom_index].room }}
                  </div>
                  <div class="">{{ classwork.title }}</div>
                  <div class="text-muted">
                    {{ setDate(classwork.due_date) }}
                  </div>
                </template>
                <template v-if="classwork.meeting_platform_id">
                  <div class="font-weight-bolder">
                    {{ classwork.classroom[classwork.classroom_index].room }}
                  </div>
                  <div class="">{{ classwork.agenda }}</div>
                  <div class="text-muted">
                    {{ setDate(classwork.end_date) }}
                  </div>
                </template>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
        </template>
      </template>
      <!-- END AKTIVITAS TERBARU -->
    </div>
    <!-- </perfect-scrollbar> -->
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";

export default {
  name: "Activity",
  data() {
    return {
      base_url: process.env.BASE_URL,
      currentUser: getUser(),
      userOn: [],
      newMessages: [],
      perPage: 10,
      currentPage: 1,
      classworks: [],
      accordionOpen: true,
    };
  },
  methods: {
    setAccordion() {
      console.log("set");
      if (this.accordionOpen) {
        this.accordionOpen = false;
      } else {
        this.accordionOpen = true;
      }
    },
    // Siswa Online
    async getUserOnline() {
      let response = await module.paginate(
        `api/users/${getUser().id}/on`,
        `?role=${getUser().role_key_name}`
      );

      this.userOn = response.data;
    },

    // 10 pesan terbaru
    async getNewChat() {
      let response = await module.paginate(
        `api/conversations/new-messages/users/${this.currentUser.id}/shows`,
        `?page=${this.currentPage}&page_size=${this.perPage}`
      );

      this.newMessages = response.data;
    },
    convertDate(date) {
      let convert_date = new Date(date).toLocaleTimeString();
      return (
        convert_date.split(":")[0] +
        ":" +
        convert_date.split(":")[1] +
        " " +
        convert_date.split(" ")[1]
      );
    },

    // tugas
    async getClasswork() {
      this.classworks = await [];
      let coming = new Date().toISOString().substr(0, 10);

      let response = await module.getSchedules(
        `api/calendars/classworks/schedules/users/${getUser().id}`,
        `?coming=${coming}`
      );

      if (response.data.assignment_classworks) {
        for (let a = 0; a < response.data.assignment_classworks.length; a++) {
          this.classworks.push(response.data.assignment_classworks[a]);
        }
      }

      if (response.data.classwork_quizzes) {
        for (let b = 0; b < response.data.classwork_quizzes.length; b++) {
          this.classworks.push(response.data.classwork_quizzes[b]);
        }
      }

      if (response.data.classwork_schedules) {
        for (let c = 0; c < response.data.classwork_schedules.length; c++) {
          this.classworks.push(response.data.classwork_schedules[c]);
        }
      }

      setTimeout(() => {
        this.classworks.sort((a, b) => (a.start_date > b.start_date ? 1 : -1));
      }, 1000);
    },
    setDate(time) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let localdate = new Date(time).toLocaleDateString("id-ID", options);
      let localtime = new Date(time).toTimeString().substr(0, 8);
      return localdate + " " + localtime;
    },

    // open chat
    closeOffcanvasMessage(index) {
      // this.$root.$emit('openActivityUser')
      // new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
      this.$root.$emit("openChat", this.newMessages[index].sender);
    },

    // redirect to classrooms
    closeOffcanvasClasswork(classroomId) {
      this.$router.push({ path: `/classrooms/detail/${classroomId}` });
    },
  },
  mounted() {
    this.getNewChat();
    this.getClasswork();
    this.getUserOnline();

    this.$root.$on("refreshUserOnline", () => {
      this.getClasswork();
    });
  },
};
</script>

<style scoped>
.user-img {
  height: 40px;
}

.tampilkan-semua {
  border-radius: 20px;
}

.symbol-badge .bg-success {
  background-color: green;
}

.bg-primary-custom {
  background-color: #4caf50;
}
</style>