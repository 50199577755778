<template>
  <!--begin::Brand-->
  <div
    class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 pt-lg-4"
  >
    <!--begin::Logo-->
    <router-link to="/" class="text-center">
      <!-- <img :src="siteLogo()" alt="Logo" class="max-h-30px" /> -->
      <img :src="configuration.logo" alt="Logo" style="max-height: 58px" />
      <span class="d-block text-white font-size-sm">Fisika UNJ</span>  
    </router-link>
    <!--end::Logo-->
  </div>
  <!--end::Brand-->
</template>

<style lang="scss" scoped>
.aside-toggle {
  outline: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import objectPath from "object-path";
import { getConfiguration, setConfiguration } from "@/core/services/jwt.service.js";

export default {
  name: "KTBrand",
  data(){
    return{
      configuration: getConfiguration()
    }
  },
  mounted() {
    this.$root.$on("configurationEditSuccessfully", () => {
      this.getSetting()
    });
  },
  methods: {
    async getSetting() {
      let response = await module.get("api/settings-no-auth/1");
      // If Data Found
      if (response != null) {
        console.log('set configuration')
        // setConfiguration(JSON.stringify(response))
        this.configuration = response
      }      
    },
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig("brand.self.theme");
      // set brand logo
      const logoObject = this.layoutConfig("self.logo");

      let logo;
      if (typeof logoObject === "string") {
        logo = logoObject;
      }
      if (typeof logoObject === "object") {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + "");
      }
      if (typeof logo === "undefined") {
        const logos = this.layoutConfig("self.logo");
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    allowMinimize() {
      return !!this.layoutConfig("aside.self.minimize.toggle");
    }
  }
};
</script>
