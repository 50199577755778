<template>
  <ul class="menu-nav">

    <li class="menu-section">
      <h4 class="menu-text">Akademik</h4>
      <i class="menu-icon flaticon-more-v2"></i>
    </li>

    <router-link
      to="/masters/users/teachers"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Dosen</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/users/students"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Mahasiswa</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/users/laboran"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Laboran</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/masters/users/admin-korprodi"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Admin Korprodi</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/users/laboratory-head"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Admin Kepala Lab</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/masters/users/admin-tu-prodi"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="user"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-users"></i>
          <span class="menu-text">Admin TU Prodi</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/masters/class-groups"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="classgroup"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-table"></i>
          <span class="menu-text">Kelompok Kelas</span>
        </a>
      </li>
    </router-link> -->
    
    <!-- <router-link
      to="/masters/competence-standards"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="competenceStandard"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <div class="menu-icon">
          <b-icon icon="list-stars"></b-icon>

          </div>
          <span class="menu-text">Standar Kompetensi</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/academic-calendars"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="academicCalendar"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-calendar-alt"></i>
          <span class="menu-text">Kalender Akademik</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/academic-calendars-year/list"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="academicCalendar"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
        @click="handleToggle"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-calendar-alt"></i>
          <span class="menu-text">Kalender Akademik 1 Tahun</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/announcements"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="announcement"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-info-circle"></i>
          <span class="menu-text">Pengumuman</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/school-years"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="schoolYear"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon mdi mdi-calendar-clock fa-2x"></i>
          <span class="menu-text">Tahun Ajaran</span>
        </a>
      </li>
    </router-link>

    <router-link
      to="/masters/majors"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="major"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-tools"></i>
          <span class="menu-text">Prodi</span>
        </a>
      </li>
    </router-link>

    <!-- <router-link
      to="/masters/grades"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="grade"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-arrow-up"></i>
          <span class="menu-text">Tingkat</span>
        </a>
      </li>
    </router-link> -->

    <router-link
      to="/masters/semesters"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-if="grade"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <i class="menu-icon fas fa-arrow-up"></i>
          <span class="menu-text">Semester</span>
        </a>
      </li>
    </router-link>

  </ul>
</template>

<script>
export default {
  data(){
    return{
      user: false,
      classgroup: false,
      competenceStandard: false,
      academicCalendar: false,
      announcement: false,
      minimumCompletenessCriteria: false,
      schoolYear: false,
      major: false,
      grade: false,
    }
  },
  methods: {
    hasActiveChildren(match) {
      //("match", match)
      return this.$route["path"].indexOf(match) !== -1;
    },
    handleToggle(){
      this.$root.$emit('closeToggleAside')
      //("jalan di sini ============================================================================")
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8001" || access_right[a] == "8002") {
          this.user = true
        }
        if (access_right[a] == "8003" || access_right[a] == "8004") {
          this.classgroup = true
        }
        if (access_right[a] == "8005" || access_right[a] == "8006") {
          this.competenceStandard = true
        }
        if (access_right[a] == "8007" || access_right[a] == "8008") {
          this.academicCalendar = true
        }
        if (access_right[a] == "8009" || access_right[a] == "8010") {
          this.announcement = true
        }
        if (access_right[a] == "8011" || access_right[a] == "8012") {
          this.minimumCompletenessCriteria = true
        }
        if (access_right[a] == "8013" || access_right[a] == "8014") {
          this.schoolYear = true
        }
        if (access_right[a] == "8015" || access_right[a] == "8016") {
          this.major = true
        }
        if (access_right[a] == "8017" || access_right[a] == "8018") {
          this.grade = true
        }
      }
    },
  },
  mounted(){
    this.checkAccess()
  }
};
</script>
