<template>
  <!-- <v-app> -->
  <div>
    <!-- <v-app> -->
    <h4>Pesan Saya</h4>
    <!--begin::Filter-->
    <form class="p-2 p-lg-3">
      <div class="d-flex">
        <div class="input-icon h-40px w-100">
          <input
            type="text"
            class="form-control form-control-lg form-control-solid h-40px"
            placeholder="Search..."
            id="searchFriendChat"
            @keyup="filterByName"
            v-model="filter.name"
          />
          <span>
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/General/Search.svg" />
              <img src="" alt="" />
            </span>
          </span>
        </div>
      </div>
    </form>
    <!--end::Filter-->
    <v-list subheader class="bg-transparent">
      <template v-for="friend in items">
        <v-hover v-slot="{ hover }" :key="friend.id">
          <v-list-item
            two-line
            :key="friend.id + friend.name"
            :class="
              hover ? 'bg-primary' : chatActive == friend.id ? 'bg-primary' : ''
            "
            :style="hover ? 'cursor:pointer' : ''"
            @click="openChat(friend)"
          >
          <template v-if="friend.is_active == 1">
            <v-badge
              bordered
              overlap
              color="deep-purple accent-4"
              dot
              offset-y="10"
            >
              <v-list-item-avatar size="40">
                <v-img :alt="`${friend.name} avatar`" :src="friend.photo"></v-img>
              </v-list-item-avatar>
            </v-badge>
          </template>
          <template v-if="friend.is_active == 0">
            <v-list-item-avatar size="40">
              <v-img :alt="`${friend.name} avatar`" :src="friend.photo"></v-img>
            </v-list-item-avatar>
          </template>
            
            <v-list-item-content>
              <v-list-item-title
                v-text="friend.name"
                :class="
                  hover
                    ? 'text-white ml-2'
                    : chatActive == friend.id
                    ? 'text-white ml-2'
                    : 'ml-2'
                "
              >
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-icon>
              <v-badge
                :content="friend.conversations.length"
                :value="friend.conversations.length"
                color="green"
                overlap
              >
                <v-icon
                  :color="
                    hover
                      ? '#FFFFFF'
                      : chatActive == friend.id
                      ? '#FFFFFF'
                      : '#244CA3'
                  "
                >
                  mdi-message-outline
                </v-icon>
              </v-badge>
            </v-list-item-icon>
          </v-list-item>
        </v-hover>
      </template>
    </v-list>
  </div>
  <!-- </v-app> -->
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';

export default {
  components: {
    Avatar,
    Badge,
    BadgeDirective
  },
  data() {
    return {
      base_url: process.env.BASE_URL,
      // filter
      filter: {
        name: "",
      },
      // Pagination
      items: [],
      perPage: 50,
      currentPage: 1,
      totalRows: 1,
      // Other
      userData: getUser(),
      chatActive: "",
      currentFriend: [],
    };
  },
  methods: {
    async getMyFriends() {
      let today = new Date().toISOString().substr(0, 10);
      let filterParams = `&role_id=${getUser().role_id}&user_id=${getUser().id}&name=${this.filter.name}`;
      let response = "";
      // if (getUser().role_id == 1) {
      //   response = await module.paginate(
      //     `api/users/my-friend-chat-for-teachers/${getUser().id}`,
      //     `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      //   );
      // } else {
      //   response = await module.paginate(
      //     `api/users/my-friend-chat-for-students/${getUser().id}`,
      //     `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      //   );
      // }
      // if(userData.role_id == 1){
          response = await module.paginate(
            `api/users/get-chat-friend`,
            `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
          );
      // }
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      this.items = response.data;
    },
    filterByName() {
      this.getMyFriends();
    },
    openChat(friend) {
      this.currentFriend = friend;
      //("set emit");
      this.$router.push(`/message-box/messages/${getUser().id}/${friend.id}`);
      this.chatActive = friend.id;
      this.getMyFriends();
      this.$root.$emit('openChatPage')

      setTimeout(() => {
        this.$root.$emit("setMessageTo", friend);
      }, 1000);
    },
  },
  mounted() {
    this.getMyFriends();
    this.$root.$on("refreshListChatUser", () => {
      this.getMyFriends();
    });

    this.$root.$on("openChat", (friend) => {
      this.openChat(friend);
    });

    // this.$root.$on("setFirstTimeOpenConversation", () => {
    //   this.$root.$emit("setMessageTo", this.currentFriend);
    // });
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
    badge:{
      inserted(el) {
        // el.badge();
        el.focus()
        //('badge', el)
      }
    }
  },
};
</script>

<style scoped>
.user-img {
  height: 40px;
}

.tampilkan-semua {
  border-radius: 20px;
}

.symbol-badge .bg-success {
  background-color: green;
}
</style>


<style lang="scss">
  @import '~vuetify/src/components/VStepper/_variables.scss';

  .custom-class {
    padding: $stepper-step-padding;
  }

  .v-badge__badge {
    background-color: #F44336 !important;
  }

  .v-badge__wrapper .deep-purple{
    background-color: #1BC5BD !important;
  }
</style>