var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v("Pesan Saya")]),_c('form',{staticClass:"p-2 p-lg-3"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"input-icon h-40px w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.name),expression:"filter.name"}],staticClass:"form-control form-control-lg form-control-solid h-40px",attrs:{"type":"text","placeholder":"Search...","id":"searchFriendChat"},domProps:{"value":(_vm.filter.name)},on:{"keyup":_vm.filterByName,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.filter, "name", $event.target.value)}}}),_c('span',[_c('span',{staticClass:"svg-icon svg-icon-lg"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Search.svg"}}),_c('img',{attrs:{"src":"","alt":""}})],1)])])])]),_c('v-list',{staticClass:"bg-transparent",attrs:{"subheader":""}},[_vm._l((_vm.items),function(friend){return [_c('v-hover',{key:friend.id,scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-list-item',{key:friend.id + friend.name,class:hover ? 'bg-primary' : _vm.chatActive == friend.id ? 'bg-primary' : '',style:(hover ? 'cursor:pointer' : ''),attrs:{"two-line":""},on:{"click":function($event){return _vm.openChat(friend)}}},[(friend.is_active == 1)?[_c('v-badge',{attrs:{"bordered":"","overlap":"","color":"deep-purple accent-4","dot":"","offset-y":"10"}},[_c('v-list-item-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"alt":`${friend.name} avatar`,"src":friend.photo}})],1)],1)]:_vm._e(),(friend.is_active == 0)?[_c('v-list-item-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"alt":`${friend.name} avatar`,"src":friend.photo}})],1)]:_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{class:hover
                    ? 'text-white ml-2'
                    : _vm.chatActive == friend.id
                    ? 'text-white ml-2'
                    : 'ml-2',domProps:{"textContent":_vm._s(friend.name)}})],1),_c('v-list-item-icon',[_c('v-badge',{attrs:{"content":friend.conversations.length,"value":friend.conversations.length,"color":"green","overlap":""}},[_c('v-icon',{attrs:{"color":hover
                      ? '#FFFFFF'
                      : _vm.chatActive == friend.id
                      ? '#FFFFFF'
                      : '#244CA3'}},[_vm._v(" mdi-message-outline ")])],1)],1)],2)]}}],null,true)})]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }