var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},[(_vm.masterTitle)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Master Penjadwalan")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),_c('router-link',{attrs:{"to":"/masters/buildings/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-school",staticStyle:{"font-size":"1.6rem"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Gedung")])])])]}}])}),_c('router-link',{attrs:{"to":"/masters/rooms/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-chalkboard",staticStyle:{"font-size":"1.6rem"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Ruang")])])])]}}])}),_c('router-link',{attrs:{"to":"/masters/times/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-clock",staticStyle:{"font-size":"1.6rem"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Jam")])])])]}}])}),_c('router-link',{attrs:{"to":"/masters/study-programs/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-book",staticStyle:{"font-size":"1.6rem"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Program Studi")])])])]}}])}),_c('router-link',{attrs:{"to":"/masters/subjects/list"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-book",staticStyle:{"font-size":"1.6rem"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Mata Kuliah")])])])]}}])}),(_vm.periodeType)?_c('router-link',{attrs:{"to":"/masters/periode-types"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-timer-sand",staticStyle:{"font-size":"1.6rem"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Jenis Periode")])])])]}}],null,false,392274217)}):_vm._e(),_vm._m(0),(_vm.announcementCategory)?_c('router-link',{attrs:{"to":"/masters/announcement-categories"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon mdi mdi-bullhorn",staticStyle:{"font-size":"1.6rem"}}),_c('span',{staticClass:"menu-text"},[_vm._v("Kategori Pengumuman")])])])]}}],null,false,2360436823)}):_vm._e(),(_vm.role)?_c('router-link',{attrs:{"to":"/masters/roles"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-circle"}),_c('span',{staticClass:"menu-text"},[_vm._v("Peran")])])])]}}],null,false,3808072472)}):_vm._e(),(_vm.settingTitle)?_c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Setting")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})]):_vm._e(),(_vm.settingView)?_c('router-link',{attrs:{"to":"/settings/edit/1"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-gear"}),_c('span',{staticClass:"menu-text"},[_vm._v("Settings")])])])]}}],null,false,469929695)}):_vm._e(),(_vm.roleAccess)?_c('router-link',{attrs:{"to":"/masters/user-managements/role-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-user-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hak Akses Peran")])])])]}}],null,false,3000444428)}):_vm._e(),(_vm.userAccess)?_c('router-link',{attrs:{"to":"/masters/user-managements/user-access"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-users-cog"}),_c('span',{staticClass:"menu-text"},[_vm._v("Hak Akses User")])])])]}}],null,false,3533789478)}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-section"},[_c('h4',{staticClass:"menu-text"},[_vm._v("Master")]),_c('i',{staticClass:"menu-icon flaticon-more-v2"})])
}]

export { render, staticRenderFns }